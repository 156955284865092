import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Permissions } from './../../pages/AdminPanel/Permissions/Permissions'
import FlowTabButtons from '../../common/FlowTabButtons/FlowTabButtons';
import './AdminPanelPage.css'

interface inputProps {
    initialTab?: string;
}

const AdminPanelPage = ({ initialTab }: inputProps) => {
    const [tab, setTab] = useState(initialTab);

    return (
        <div className='wrapperDiv'>
                <div className='groupsSubTabPageStyle' >
                    <Box sx={{marginTop: '30px', marginBottom: '25px'}}>
                    <Typography sx={{marginRight:'630px', fontSize: '25px', paddingBottom: '20px', paddingTop: '40px', paddingLeft: '35px'}} variant='subheader'>{'Admin Panel'}</Typography>
                    </Box>
                <Box className='pageContentAdminPanel'>
                        <Grid container columnSpacing={6}>
                            <Grid item xs={2.3} className='leftContent'>
                                <Box className='leftNav'>
                                    <FlowTabButtons
                                        promotionMechanic={'Admin Panel'}
                                        tab={tab}
                                        setTab={setTab}
                                    />
                                </Box>
                            </Grid>
                            <Grid sx={{width: '90%'}} item xs={9.7}>
                            {tab === 'permissions' && <Permissions />}
                            </Grid>
                        </Grid>
                    </Box>
                </div>

        </div>
    );
};

export { AdminPanelPage };
