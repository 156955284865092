import { useState } from 'react';
import { Box, Typography } from '@mui/material'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import './PermissionTabStyles.css'
import { groupNameDescriptionMap } from './adminpanel-constants'
import { Users } from './Users'
import { GroupSubTabInputProps } from './permissionTabTypes'


function Groups({data, setAdminPanelData}: GroupSubTabInputProps) {
const [groupSelected, setGroupSelected] = useState<string>('none')

if (groupSelected === 'none') {
    return (
        <Box className='groupSubNabBoxStyle'>
          <TableContainer component={Paper} className='groupSubNavTableContainerStyle'>
            <Table className='groupSubTabTableStyle'>
              <TableHead className='groupSubTabTableHeadStyle'>
                <TableRow className='groupSubTabTableHeadRowStyle'>
                  <TableCell className='groupSubTabGroupNameCellStyle' align="left">
                  Group Name
                  </TableCell>
                  <TableCell className='groupSubTabGroupDescCellStyle' align="left">
                  Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
            {groupNameDescriptionMap.map((group, i)=>
                (
                <TableRow key={i} onClick={()=> setGroupSelected(group.cognitoGroupName)} hover className='groupSubTabBodyTableRow'>
                <TableCell>
                <Typography className='groupSubTabBodyGroupName'> {group.groupName} </Typography>
                </TableCell>
                <TableCell>
                <Typography className='groupSubTabBodyGroupDescription'>{group.description}</Typography>
                </TableCell>
               </TableRow>
                )
            )}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
      );
}

return (
    <Users data={data} userListVariant = {groupSelected} setAdminPanelData={setAdminPanelData} />
);

}

export { Groups };
