export const groupNameDescriptionMap = [
    // cognitoGroupName is the name of the group in the cognito console.
    // It might differ than the groupName shown in the front end due to the inability to have white spaces in the naming in the cognito console.
    {
        groupName: 'Admin',
        description: 'The current ADMIN group which will be superseded by the Super Admin group',
        cognitoGroupName: 'Admin'
    },
    {
        groupName: 'Operations',
        description: 'The current Operations group which will be removed and its members placed accordingly among other groups upon confirmation',
        cognitoGroupName: 'Operations'
    },
    {
        groupName: 'Super Admin',
        description: 'Members of this group can perform create, edit, archive actions and provide user permissions.',
        cognitoGroupName: 'SuperAdmin'
    },
    {
        groupName: 'Regional Admin',
        description: 'Members of this group can create, edit, archive.',
        cognitoGroupName: 'RegionalAdmin'
    },
    {
        groupName: 'Promotion Manager',
        description: 'Members of this group can perform create, edit, and archive actions for specific campaigns and markets.',
        cognitoGroupName: 'PromotionManager'
    },
    {
        groupName: 'Read Only',
        description: 'Members of this group can only view information.',
        cognitoGroupName: 'ReadOnly'
    }
]

export const subTabLabelStylesMap = {
    'Groups': 'groupsSubTabPageStyle',
    'Users': 'usersSubTabPageStyle'
}

export const permissionTabNavTabLabels = [
    'Groups', 'Users'
]

export enum AdminPanelPaths {
    AdminPanel = '/adminPanel',
}
