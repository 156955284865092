import { API, graphqlOperation } from 'aws-amplify';
import { getAdminPanelData } from '../../../graphql/queries';
import { groupNameDescriptionMap } from './adminpanel-constants'


export const mutateUserGroups = async (inputEmail: string, inputEventType: string, inputTargetGroup: string, inputUserName: string, inputUserPoolId: string, setNotificationState: any) => {
    try {
        const result = await API.graphql(
            graphqlOperation(getAdminPanelData, {adminPanelLambdaInput: {
                email: inputEmail,
                eventType: inputEventType,
                targetGroup: inputTargetGroup,
                userName: inputUserName,
                userPoolId: inputUserPoolId
            }
            })
        )
        setNotificationState(
            {
                open: true,
                title: `User Successfully ${inputEventType === 'addUser' ? 'added to' : 'removed from'} the ${groupNameDescriptionMap.find((groupObj) => groupObj.cognitoGroupName === inputTargetGroup)?.groupName} Group!`,
                content: '',
                level: "success"
            }
        )
        return result;
    } catch (e) {
    console.log(`${inputEventType === 'addUser' ? 'Addition' : 'Removal'} failed with`, e)
    setNotificationState(
        {
            open: true,
            title: 'An error occurred. Please check the console for more details!',
            content: '',
            level: "error"
        }
    )
    }
};
