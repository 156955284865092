export interface UserObjectContainedGroup {
    CreationDate: string,
    Description: string,
    GroupName: string,
    LastModifiedDate: string,
    UserPoolId: string
}

export interface UserObject {
    userId: string,
    email: string,
    groups: UserObjectContainedGroup[]
}


export interface UsersPerGroupType {
        [groupName : string] : string[]
    }

export interface GroupObject {
    CreationDate: string,
    Description: string,
    GroupName: string,
    LastModifiedDate: string,
    Precedence: number,
    RoleArn: string,
    UserPoolId: string
}
export interface GroupSubTabInputProps {
    data?: AdminPanelPermissionsDataType;
    setAdminPanelData?: any
}

export interface UserSubTabInputProps {
    data: AdminPanelPermissionsDataType;
    userListVariant: string;
    setAdminPanelData?: React.Dispatch<React.SetStateAction<AdminPanelPermissionsDataType>>;
    }


export interface AdminPanelPermissionsDataType {
        allUsers: UserObject[],
        usersPerGroup: UsersPerGroupType,
        groupObjects: GroupObject
}

export const adminPanelDataInitialState: AdminPanelPermissionsDataType = {
    allUsers: [],
    usersPerGroup: {},
    groupObjects: {
        CreationDate: '',
        Description: '',
        GroupName: '',
        LastModifiedDate: '',
        Precedence: 0,
        RoleArn: '',
        UserPoolId: ''
    }
}




