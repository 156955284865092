import { useEffect, useState } from 'react';
import { Modal, Grid, Button, Box } from '@mui/material';
import { generateRadioButtonConfigs } from '../../constants/prize-constants';
import { RadioButton } from '../inputComponents/RadioButton';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';
import TooltipText from '../inputComponents/TooltipText';
import { ToolTipPlacement } from '../../types/componentTypes/tooltip';

const PrizeImageMetadataModal = ({ props }) => {

    const {
        showModal,
        language,
        url,
        formik,
        setModalState,
    } = props;

    const [radioButtonConfigs, setRadioButtonConfigs] = useState([]);

    const handleDiscardAndCloseModal = () => {
        const updatedData = { ...formik.values.images_metadata };
        if (Object.keys(updatedData).length) {
            delete updatedData[language][`${url}`];
            if (Object.keys(updatedData[language]).length === 0) {
                delete updatedData[language];
            }
            if (Object.keys(updatedData).length === 0) {
                formik.setFieldValue('images_metadata', undefined);
            } else {
                formik.setFieldValue('images_metadata', updatedData);
            }
        }

        setModalState({open: false, index: null, url: '' });
    };


    const handleSaveModal = () => {
        const selectedPriority = formik.values.images_metadata[language][url].priority;
        if (selectedPriority) {
            formik.setFieldValue(
                `images_metadata[${language}]["${url}"].priority`,
                parseInt(selectedPriority)
              );
        }
        formik.setFieldValue(
            `images_metadata[${language}].["${url}"].url`, url)
        setModalState({open: false, index: null, url: '' });
    };

    const handleCloseModal = () => {
        setModalState({open: false, index: null, url: '' });
    }

    useEffect(() => {
        setRadioButtonConfigs(generateRadioButtonConfigs(url, language));
    }, [url, language]);


    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
        >
            <Box className='modal_main'>
                <Grid container justifyContent='center' spacing={2} direction={'column'}>
                    {radioButtonConfigs.map((config, index) => (
                        <Grid item key={index}>
                            <RadioButton
                                formLabel={config.formLabel}
                                row={config.row || false}
                                formik={formik}
                                values={config.values}
                                radioGroupName={config.radioGroupName}
                            />
                        </Grid>
                    ))}
                    <Grid item key='img-alt-text'>
                        <TooltipText
                            text={'Prize image alt text'}
                            tooltip={'The purpose of the Prize Image alternative text field is to provide a brief description of the image. If the image doesn’t load, the alt will be displayed instead.'}
                            textVariant='body1'
                            iconSize='medium'
                            styleName='prize-tooltip-text'
                            placement={ToolTipPlacement.RightEnd}
                        />
                        <TextFieldWrapper
                            name={`imgAltText_${language}_${url.replace(/[^a-zA-Z0-9]/g, '_')}`}
                            value={formik.values.images_metadata?.[language]?.[url]?.alt}
                            multiline
                            sx={{ width: "350px"}}
                            inputProps={{ maxLength: 1000 }}
                            helperText={'The maximum length is 1000 characters'}
                            onChange={(event) => {
                                formik.setFieldValue(`images_metadata.[${language}].["${url}"].alt`, event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button  variant="outlined" onClick={handleDiscardAndCloseModal}>
                            Discard
                        </Button>
                        <Button variant="contained" onClick={handleSaveModal}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default PrizeImageMetadataModal;
