const baseButtons = ['generalInfo', 'additionalDetails', 'currencies', 'mixcodes']
const peButtons = ['limits', 'participationExport'];
const iwButtons = ['limits', 'prizes', 'winningMoments', 'vouchers']
const cngButtons = ['prizes', 'allocationRules', 'vouchers']
const adminPanelButtons = ['permissions', 'emailTemplates', 'emailNotifications', 'replicationManagement']

export const getFlowTabButtons = (promotionMechanic: string) => {
    let flowButtons = [];

    switch (promotionMechanic) {
        case 'Instant Win':
            flowButtons = iwButtons;
            break;
        case 'Prize Draw (Lottery)':
            flowButtons = peButtons;
            break;
        case 'Collect & Get':
            flowButtons = cngButtons;
            break;
        case 'Admin Panel':
            flowButtons = adminPanelButtons
            break;
        case 'Auto Collect & Get':
            flowButtons = cngButtons;
            break;
        default:
            flowButtons = [];
    }

    return promotionMechanic === 'Admin Panel' ? [...flowButtons] : [...baseButtons, ...flowButtons];
}

export const flowTabButtonsMap = {
    generalInfo: {
        label: "General Info"
    },
    prizes: {
        label: "Prizes"
    },
    vouchers: {
        label: "Vouchers"
    },
    winningMoments: {
        label: "Winning Moments"
    },
    limits: {
        label: "Limits"
    },
    allocationRules: {
        label: "Allocation Rules"
    },
    mixcodes: {
        label: "MiX Codes"
    },
    currencies: {
        label: "Currencies"
    },
    participationExport: {
        label: "Participation Export"
    },
    additionalDetails: {
        label: "Additional Details"
    },
    permissions: {
        label: "Permissions"
    },
    emailTemplates : {
        label: "Email Templates"
    },
    emailNotifications: {
        label: "Email Notifications"
    },
    replicationManagement: {
        label: "Replication Management"
    }
}
