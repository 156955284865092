import { Users } from './Users'
import { API, graphqlOperation } from 'aws-amplify';
import { getAdminPanelData } from './../../../graphql/queries';
import { useState, useEffect } from 'react';
import { NavBar } from '../../../common/NavBar/NavBar'
import { Groups } from '../Permissions/Groups'
import './PermissionTabStyles.css'
import { ThemeProvider } from '@emotion/react';
import MUItheme from './../../../MuiTheme';
import GridSkeleton from './../../../common/Skeletons/GridSkeleton';
import { createTheme } from '@mui/material/styles';
import ProgressBar from './../../../common/ProgressBar/ProgressBar'
import { permissionTabNavTabLabels } from './adminpanel-constants'
import { Typography } from '@mui/material';
import './PermissionTabStyles.css';
import { AdminPanelPermissionsDataType, adminPanelDataInitialState } from './permissionTabTypes';

const theme = createTheme(MUItheme);

const Permissions = () => {
    const [adminPanelData, setAdminPanelData] = useState<AdminPanelPermissionsDataType>(adminPanelDataInitialState)
    const [loading, setLoading] = useState(true)

    const getAdminPanelDataFunc = async () => {
        const result = await API.graphql(
            graphqlOperation(getAdminPanelData, {
            })
        )
        return result;
    };

    useEffect(() => {
        getAdminPanelDataFunc().then((adminPanelData: any) => {
            setAdminPanelData(JSON.parse(adminPanelData?.data?.getAdminPanelData?.userGroupMap));
            setLoading(false);
        })
    }, []);

    if(loading){
        return (
            <ThemeProvider theme={theme}>
                <ProgressBar />
                <GridSkeleton items={4} />
            </ThemeProvider>
        )
    }

    return (
        <>
        <Typography className='permissionTabLabelStyle '> Permissions </Typography>
        <NavBar tabLabels={permissionTabNavTabLabels} components={[<Groups data={adminPanelData} setAdminPanelData={setAdminPanelData}/>, <Users data={adminPanelData} userListVariant={'allUsers'} setAdminPanelData={setAdminPanelData}/>]}/>
        </>
    );
}

export { Permissions }
