import moment from 'moment-timezone';
import { FormikContextType } from 'formik';
import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { ConfigType, PrizeDrawConfig, configurationParameters } from '../configuration';
import { notificationType } from '../notifications';
import { CurrencyTableItem } from './CurrencyTypes';


export interface EditPromotionGeneralInfoProps {
    campaignData: any;
    config: ConfigType | PrizeDrawConfig;
    setNotificationState: React.Dispatch<React.SetStateAction<notificationType>>;
    setLoading: Function;
    promotionMechanic: string;
    promotionVariation: string;
    handleTabChange: Dispatch<SetStateAction<boolean>>;
    hasPrizes: boolean;
}

export interface ConfigFlow {
    [key: string]: {
        checkerLambdas?: string[];
        flowLambdas: string[];
        params?: {
            [key: string]: string | boolean;
        };
    };
}

export type editPromotionFormikState = ConfigType & {
    configurationParameters: configurationParameters;
    tempParams: {
        imgUrl: string;
        autoRedeemPrizeLambda: boolean;
        promoMechanic: string;
    };
}

export enum ConfigurationSubMechanics {
    CollectAndGet = 'Collect & Get',
    AutoCollectAndGet = 'Auto Collect & Get',
}

export const Flows: { [key: string]: Partial<ConfigFlow> } = {
    autoRedeemCnG: {
        autoRedeemCnG: {
            flowLambdas: [
                'burnPincodes',
                'pincodeToCurrency',
                'transactionLambda',
                'autoRedeemPrizeLambda',
            ],
        },
    },
    standardCG: {
        redeemPincodeForCurrencies: {
            flowLambdas: ['burnPincodes', 'pincodeToCurrency'],
        },
    },
};

export const generalInfoInitialState: editPromotionFormikState = {
    promotionId: '',
    configurationId: '',
    configurationParameters: {
        country: '',
        language: '',
        configurationStartUtc: moment().unix() * 1000,
        configurationEndUtc: moment().add(14, 'days').unix() * 1000,
        configurationDatesTimezone: '',
        configurationSubMechanic: '',
        emailTemplateId: '',
        ajoEmailTemplate: '',
        additionalInformation: {
            name: '',
            description: '',
            shortDescription: '',
            imgUrl: '',
        },
    },
    tempParams: {
        imgUrl: '',
        autoRedeemPrizeLambda: false,
        promoMechanic: '',
    },
    flow: {},
};

export enum PromoMechanic {
    InstantWin = 'Instant Win',
    CollectAndGet = 'Collect & Get',
    PromoEntry = 'Prize Draw (Lottery)',
    AutoRedeemCnG = 'Auto Collect & Get',
}

export enum PromoVariation {
    CollectAndGetAuto = 'Collect & Get + Auto-Redeem',
    InstantWinAW = 'Instant Win (Always Win)',
    InstantWinWM = 'Instant Win (Winning Moments)',
    InstantWinWMGP = 'Instant Win (Winning Moments + Guaranteed Prize)',
    InstantWinIF = 'Instant Win (Winning Moments + Interrupted Flow)',
}

export interface EditPromotionGeneralInfoFormProps {
    campaignData: any;
    promotionMechanic: string;
    promotionVariation: string;
    emailTemplates: Array<object | undefined>;
    updateOnCountry: (event: SyntheticEvent, params: object) => void;
    handleTabChange: Dispatch<SetStateAction<boolean>>;
    updatePromoSubMechanic?: (
        event: SyntheticEvent,
        params: {
            option: PromoVariation;
            formik: FormikContextType<editPromotionFormikState>;
        }
    ) => void;
    hasPrizes: boolean;
}




export interface EditCurrenciesState {
    tempParams: {
        availableCurrencies: CurrencyTableItem[];
        useCurrency: boolean;
        flowOption: string;
        currencyNames: string[];
        currencyValidity: boolean;
        validityDays: number;
        totalCurrencyAccumulated: boolean;
    };
    flowLambdas?: string[];
    params: {
        reduceAmount: { currencyId: string; amount: number }[];
    };
    configurationParameters: configurationParameters;
}

export interface ReduceAmount {
    currencyId: string;
    amount: number;
}

export interface CurrencySubmitValues {
    configurationId: string;
    configurationParameters: {
        additionalInformation: {
            name: string;
            totalCurrencyAccumulated?: boolean;
        };
        configurationDatesTimezone: string;
        configurationEndUtc: number;
        configurationStartUtc: number;
        configurationSubMechanic: string;
        country: string;
        currencies: string[];
        language: string;
        startEndDateCheck: boolean;
        validity?: { [key: string]: number };
    };
    flow: {
        instantWin?: {
            checkerLambdas: string[];
            flowLambdas: string[];
            params: {
                algorithm: string;
                reduceAmount: Array<ReduceAmount>;
            };
        };
        redeemPincodeForCurrencies?: {
            checkerLambdas: string[];
            flowLambdas: string[];
        };
    };
    checkerLambdas: string[];
    flowLambdas: string[];
    params: {
        reduceAmount: Array<ReduceAmount>;
    };
    promotionId: string;
    tempParams: {
        availableCurrencies: Array<CurrencyTableItem>;
        currencyNames: string[];
        flowOption: string;
        useCurrency: boolean;
        currencyValidity: boolean;
        validityDays: number;
        totalCurrencyAccumulated: boolean;
    };
}

export type PrizeDrawParticipationsRes = {
    getPrizeDrawParticipations: {
        body: string;
    };
};

export type LotteryParticipationsRes = {
    getLotteryParticipations: {
        body: string
    }
  };
