import { Box, Typography, Grid, Link, TextField, FormHelperText } from '@mui/material';
import { Fragment } from 'react';
import { Field, getIn } from 'formik';
import { CheckboxComponent } from '../inputComponents/CheckboxComponent';
import { InputComponent } from '../inputComponents/InputComponent';
import { TooltipText } from '../inputComponents/TooltipText';
import { SelectOption } from '../selectComponents/SelectOption';
import { GridItems } from './GridItems';
import '../../pages/AssignPromotion/AssignPromotionPage.css';
import './GridItems.css';
import {
    IRenderMixCodeComponents,
    MixCodesProps,
    usingVPC,
    getMixCodeURI,
    MixCodesConfigurationParams,
} from '../../types/componentTypes/mixcodes-types';

const mixCodesRef = 'https://wiki.coke.com/confluence/pages/viewpage.action?pageId=6127906';

const alphaNumaricRegex = /^[a-zA-Z0-9]+$/

const mapCheckboxState: { [key: string]: string[] } = {
    'tempParams.burnPincodes': [
        'secrets.mixCodesParameters',
        'tempParams.codeType',
        'tempParams.pincodeOriginValitidy',
    ],
    'tempParams.pincodeOriginValitidy': ['params.campaignIds', 'params.lotIds'],
};
const emptyValues = {
    'tempParams.burnPincodes': [
        { 'secrets.mixCodesParameters': [{ programId: '', secret: '', uri: '' }] },
        { 'tempParams.codeType': [] },
        { 'tempParams.pincodeOriginValitidy': false },
    ],
    'tempParams.pincodeOriginValitidy': [{ 'params.campaignIds': [''] }, { 'params.lotIds': [''] }],
};
const mapActions = {
    'tempParams.pincodeOriginValitidy': { param: 'checkerLambdas', value: 'pincodeOriginValidityCheckerLambda' },
    'tempParams.burnPincodes': { param: 'flowLambdas', value: 'burnPincodes' },
};

function MixCodesDetails({ formik, isEdit = false, mainBoxClass }: MixCodesProps) {
    const addValue = () => {
        formik.setFieldValue('secrets.mixCodesParameters', [
            ...formik.values.secrets.mixCodesParameters,
            { programId: '', secret: '', uri: '' },
        ]);
    };

    const removeMixCodeValue = (params: { index: number }) => {
        formik.setFieldValue(
            'secrets.mixCodesParameters',
            formik.values.secrets.mixCodesParameters.filter((_: any, i: number) => i !== params.index)
        );
        const codeValues = getIn(formik.values, 'tempParams.codeType');
        formik.setFieldValue(
            'tempParams.codeType',
            codeValues.filter((_: any, i: number) => i !== params.index)
        );
    };

    const handleMixcodesUri = (_: unknown, params: { index: number; option: any }) => {
        if (usingVPC()) {
            formik.setFieldValue(
                `secrets.mixCodesParameters[${params.index}]['uri']`,
                getMixCodeURI.uriVPCMap[params.option]
            );
        } else {
            formik.setFieldValue(
                `secrets.mixCodesParameters[${params.index}]['uri']`,
                getMixCodeURI.uriMap[params.option]
            );
        }
    };

    const removeValue = (params: { label: string; index: number }) => {
        formik.setFieldValue(
            params.label,
            getIn(formik.values, params.label).filter((_: any, i: number) => i !== params.index)
        );
    };

    const addStringValue = (params: { label: string; index: number }) => {
        formik.setFieldValue(params.label, [...getIn(formik.values, params.label), '']);
    };

    const removePincodeOriginValitidyFromCheckers = () => {
        const subAction = mapActions['tempParams.pincodeOriginValitidy'];
        formik.setFieldValue(
            subAction.param,
            formik.values[subAction.param].filter((value: string) => value !== subAction.value)
        );
    };

    const handleCheckbox = (e: any) => {
        const name = e.target.name;
        const checked = e.target.checked;
        const actions = mapActions[name as keyof typeof mapActions];

        if (!checked) {
            if (!isEdit) {
                mapCheckboxState[name].forEach((val) => {
                    formik.setFieldValue(val, getIn(formik.initialValues, val) || '');
                    formik.setFieldTouched(val, false);
                });

                if (name === 'tempParams.burnPincodes') {
                    mapCheckboxState['tempParams.pincodeOriginValitidy'].forEach((val) => {
                        formik.setFieldValue(val, getIn(formik.initialValues, val) || '');
                        formik.setFieldTouched(val, false);
                    });
                    removePincodeOriginValitidyFromCheckers();
                }
            } else {
                if (name === 'tempParams.burnPincodes') {
                    for (const keyValue in emptyValues) {
                        emptyValues[keyValue as keyof typeof emptyValues].forEach((val: any) => {
                            const [[key, value]] = Object.entries(val);
                            formik.setFieldValue(key, value || '');
                        });
                    }
                    removePincodeOriginValitidyFromCheckers();
                } else {
                    emptyValues[name as keyof typeof emptyValues].forEach((val: any) => {
                        const [[key, value]] = Object.entries(val);
                        formik.setFieldValue(key, value || '');
                    });
                    removePincodeOriginValitidyFromCheckers();
                }
            }
            formik.setFieldValue(
                actions.param,
                formik.values[actions.param].filter((value: string) => value !== actions.value)
            );
        } else {
            formik.setFieldValue(actions.param, [...getIn(formik.values, actions.param), actions.value])
        }
    };

    const handleCodeOriginInputValidation = ({
        event,
        string,
        index,
    }: {
        event: string;
        string: MixCodesConfigurationParams;
        index: number;
    }) => {
        let error = '';
        const parsedValue = event;
        const isValidString = parsedValue && alphaNumaricRegex.test(parsedValue) && parsedValue?.length === 26;
        if (event && parsedValue && isValidString) return;
        const relatedValueString: MixCodesConfigurationParams = string === 'lotIds' ? 'campaignIds' : 'lotIds';
        const relatedParsedValue = getIn(formik.values, `params.${relatedValueString}[${index}]`);
        const isValidRelatedString = relatedParsedValue && alphaNumaricRegex.test(relatedParsedValue) && relatedParsedValue?.length === 26;

        if (!event && (!relatedParsedValue && !isValidRelatedString))
            return (error = `Can only be optional if ${relatedValueString} at position ${
                index + 1
            } is present and is a valid string.`);
       
        if ((!isValidRelatedString || !isValidString) && ((parsedValue && !(parsedValue?.length === 26)) || (relatedParsedValue && !(relatedParsedValue.length === 26))))
            return (error = 'This field must contain alphanumeric string with 26 characters.');

        if (!isValidString && !isValidRelatedString)
            return (error = 'This field must contain alphanumeric string with 26 characters.');

        if (((parsedValue && !alphaNumaricRegex.test(parsedValue)) || (relatedParsedValue && !alphaNumaricRegex.test(relatedParsedValue))) && (isValidRelatedString || parsedValue))
            return (error = 'This field must contain alphanumeric string with 26 characters.');

        return error;
    };

    const renderMixCodeOriginComponents = ({
        mixCodesConfigurationParam,
        titleText,
        items,
    }: IRenderMixCodeComponents) =>
        items.map((_: unknown, i: number) => (
            <GridItems
                key={i}
                formik={formik}
                index={i}
                component={
                    <Grid container direction='column' justifyContent='center' alignItems='"flex-start"'>
                        <Grid item xs={11}>
                            <Typography variant='caption'>{titleText}</Typography>
                        </Grid>

                        <Grid item>
                            <Field
                                fullWidth={true}
                                data-testid={`${mixCodesConfigurationParam}[${i}]-input`}
                                key={`${mixCodesConfigurationParam}[${i}]`}
                                as={TextField}
                                validate={(event: string) =>
                                    handleCodeOriginInputValidation({
                                        event,
                                        string: mixCodesConfigurationParam,
                                        index: i,
                                    })
                                }
                                name={`params.${mixCodesConfigurationParam}[${i}]`}
                                error={
                                    getIn(formik.errors, `params.${mixCodesConfigurationParam}[${i}]`) ? true : false
                                }
                            />
                            <FormHelperText
                                key={`${mixCodesConfigurationParam}${i}-helper`}
                                data-testid={`${mixCodesConfigurationParam}[${i}]-error`}
                                error={
                                    getIn(formik.errors, `params.${mixCodesConfigurationParam}[${i}]`) ? true : false
                                }>
                                {getIn(
                                    getIn(formik.touched, `params.${mixCodesConfigurationParam}[${i}]`) &&
                                        formik.errors,
                                    `params.${mixCodesConfigurationParam}[${i}]`
                                )}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                }
                addAction={addStringValue}
                removeAction={removeValue}
                customParam={{ label: `params.${mixCodesConfigurationParam}` }}
                formikValueArray={`params.${mixCodesConfigurationParam}`}
            />
        ));

    return (
        <Box className={mainBoxClass || 'text-box'}>
            {!isEdit && (
                <>
                    <TooltipText
                        text='MiX Codes Details'
                        textVariant='h4'
                        tooltip={
                            <Typography variant='body2'>
                                <Link className='tooltip-link' href={mixCodesRef} target='_blank'>
                                    Learn more
                                </Link>{' '}
                                about MiX Codes solution that is used to generate codes.
                            </Typography>
                        }
                    />
                    <Typography variant='body1' gutterBottom>
                        Enter MiX Codes details if pincodes and/or viral codes are required for participation in this
                        promotion
                    </Typography>
                </>
            )}
            <Box className='functionality-items'>
                <CheckboxComponent
                    data-testid='codeOriginValidation-checkbox'
                    fieldName={'tempParams.burnPincodes'}
                    label='Codes are required for participation'
                    customAction={handleCheckbox}
                />
                {formik.values.tempParams.burnPincodes && (
                    <Grid container className='gridMixcodes'>
                        {formik.values.secrets.mixCodesParameters.map((_: Object, index: number) => (
                            <Fragment key={index}>
                                <GridItems
                                    formik={formik}
                                    index={index}
                                    component={
                                        <Box className='mixcodes-box'>
                                            <InputComponent
                                                formik={formik}
                                                inputValue={getIn(formik.values, `secrets.mixCodesParameters[${index}]['programId']`)}
                                                formikLabel={`secrets.mixCodesParameters[${index}]['programId']`}
                                                title='Program ID'
                                                titleVariant='caption'
                                                label=''
                                                type='string'
                                            />
                                            <InputComponent
                                                formik={formik}
                                                inputValue={getIn(formik.values, `secrets.mixCodesParameters[${index}]['secret']`)}
                                                formikLabel={`secrets.mixCodesParameters[${index}]['secret']`}
                                                title='Secret Key'
                                                titleVariant='caption'
                                                label=''
                                                multiline={true}
                                            />
                                            <SelectOption
                                                formik={formik}
                                                formikValue={`tempParams.codeType[${index}]`}
                                                customAction={handleMixcodesUri}
                                                customParam={{ index }}
                                                optionsList={['Pincodes', 'Viral Codes']}
                                                title='Code Type'
                                                titleVariant='caption'
                                                errorPath={`secrets.mixCodesParameters`}
                                            />
                                        </Box>
                                    }
                                    addAction={addValue}
                                    removeAction={removeMixCodeValue}
                                    formikValueArray={'secrets.mixCodesParameters'}
                                />
                            </Fragment>
                        ))}
                    </Grid>
                )}
                <CheckboxComponent
                    fieldName={'tempParams.pincodeOriginValitidy'}
                    label='Code origin validation'
                    customAction={handleCheckbox}
                    disabledCondition={!formik.values.tempParams.burnPincodes}
                />
                {formik.values.tempParams.pincodeOriginValitidy && (
                    <Grid container className='gridPincodeOrigin'>
                        {/* render the lotId Components */}
                        {renderMixCodeOriginComponents({
                            items: formik.values.params?.lotIds?.[0] ? formik.values.params.lotIds : [0],
                            titleText: 'Enter Allowed Lot ID',
                            mixCodesConfigurationParam: 'lotIds',
                        })}

                        {/* render the campaignId Components */}
                        {renderMixCodeOriginComponents({
                            items: formik.values.params?.campaignIds?.[0] ? formik.values.params.campaignIds : [0],
                            titleText: 'Enter Allowed Campaign ID',
                            mixCodesConfigurationParam: 'campaignIds',
                        })}
                    </Grid>
                )}
            </Box>
        </Box>
    );
}

export { MixCodesDetails };
