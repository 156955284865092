import { useState, useEffect } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TableFooter,
  InputAdornment,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import { DialogBox } from '../../../common/Dialog/DialogBox';
import { chunker, filterList, returnGroupNames } from './adminPanelUtils';
import ClearIcon from '@mui/icons-material/Clear';
import { Notification } from '../../../common/Notification/Notification';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SubNav } from '../../../common/SubNav/SubNav';
import { mutateUserGroups } from './api-calls';
import { groupNameDescriptionMap } from './adminpanel-constants';
import TextFieldWrapper from '../../../common/TextFieldWrapper/TextFieldWrapper';
import SearchRounded from '@mui/icons-material/SearchRounded';
import { defaultNotifState } from './../../../constants/currency-constants';
import {notificationType} from './../../../../src/types/notifications'
import './PermissionTabStyles.css';
import { AddUsersToGroupModal } from './apPermissionsSpecificModals/AddUsersToGroupModal';
import { UserSubTabInputProps, UserObject } from './permissionTabTypes'


function Users({ data, userListVariant, setAdminPanelData }: UserSubTabInputProps) {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<UserObject>(undefined);
  const [showRemoveUserDialog, setShowRemoveUserDialog] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [filteredList, setFilteredList] = useState<any>(chunker(data?.allUsers, 10));
  const [inputFilterText, setInputFilterText] = useState<string>('');
  const [notificationState, setNotificationState] = useState<notificationType>(defaultNotifState);

  const linksMapper = [
    { name: 'Groups', href: `/adminPanel` },
    {
      name: `${groupNameDescriptionMap.find((groupObj) => groupObj.cognitoGroupName === userListVariant)?.groupName}`,
    },
  ];

  const inputHandler = (e: any) => {
    setPage(0);
    setInputFilterText(e.target.value);
  };

  const copyEmailToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    setNotificationState({
      open: true,
      title: 'User E-Mail Copied!',
      content: '',
      level: 'success',
    });
  };

  useEffect(() => {
    let dataSet = userListVariant === 'allUsers' ? data?.allUsers : data?.usersPerGroup[userListVariant];
    setFilteredList(filterList(dataSet || [], inputFilterText, rowsPerPage));
  }, [rowsPerPage, page, inputFilterText, showRemoveUserDialog, data, userListVariant]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleAddUser = async (inputSelectedUser: { email: string; userId: string; groups: { UserPoolId: string }[] }) => {
    await mutateUserGroups(
      inputSelectedUser?.email,
      'addUser',
      userListVariant,
      inputSelectedUser?.userId,
      inputSelectedUser?.groups[0]?.UserPoolId,
      setNotificationState
    ).then((result: any) => {
      setAdminPanelData(JSON.parse(result?.data?.getAdminPanelData?.userGroupMap));
    });
    setShowAddUserModal(false);
  };

  const handleRemoveUser = async () => {
    await mutateUserGroups(
      selectedUser?.email,
      'removeUser',
      userListVariant,
      selectedUser?.userId,
      selectedUser?.groups[0]?.UserPoolId,
      setNotificationState
    ).then((result: any) => {
      setAdminPanelData(JSON.parse(result?.data?.getAdminPanelData?.userGroupMap));
    });
    setShowRemoveUserDialog(false);
    setSelectedUser(undefined);
  };

  const handleAddUserModalClosure = () => {
    setShowAddUserModal(false);
  };

  const handleRemoveUserDialogClosure = () => {
    setShowRemoveUserDialog(false);
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <div className="userTableDiv">
      <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
      <DialogBox
        isOpen={showRemoveUserDialog}
        actionButtonLabels={['Cancel', 'Remove']}
        actionButtonHandlers={[handleRemoveUserDialogClosure, handleRemoveUser]}
        buttonDisabled={false}
        dialogCloseHandler={handleRemoveUserDialogClosure}
        dialogContent={'Are you sure you want to remove the user from this group?'}
        timeoutBeforeSubmit={false}
      />

      {userListVariant !== 'allUsers' && (
        <>
          <Box className='usersSubNavTabLinksMapperBoxStyle'>
            <SubNav linksMapper={linksMapper} textVariant="overline" linkClass="subnav-edit-promo" />
          </Box>
          <Button
            className='userSubNavTabAddUserButtonStyle'
            onClick={() => setShowAddUserModal(true)}
            variant="contained"
          >
            + Add User
          </Button>
          {showAddUserModal && (
            <AddUsersToGroupModal
              // This is required since just having the isOpen parameter only
              // toggles whether the component is shown and does not actualy dismount it.
              data={data}
              groupUserList={data?.usersPerGroup[userListVariant]}
              groupName={userListVariant}
              isOpen={showAddUserModal}
              handleModuleClosure={handleAddUserModalClosure}
              setNotificationState={setNotificationState}
              handleAddUser={handleAddUser}
            />
          )}
        </>
      )}
      <TextFieldWrapper
        id="search-adminpanel-outlined-basic"
        helperText="Search for Users"
        label="Search by e-mail"
        variant="filled"
        size={'small'}
        className="searchBarStyle"
        InputProps={{
          style: {
            backgroundColor: 'off-white',
          },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
        onChange={inputHandler}
      />
      <TableContainer component={Paper} className={'assign-promo-list-table'}>
        <Table className="tableClass" aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell className="rowCells" align="left">
                E-Mail
              </TableCell>
              <TableCell className="rowCells" align="left">
                {userListVariant !== 'allUsers' ? 'Remove User From Group' : 'Assigned Groups'}
              </TableCell>
            </TableRow>
          </TableHead>
          {filteredList.length > 0 && (
            <TableBody>
              {filteredList[page].map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" sx={{ width: '660px', fontSize: '16px' }}>
                      {item.email ? item.email : item}
                      {userListVariant === 'allUsers' && (
                        <ContentCopyIcon
                          className='usersSubNavTabCopyUserEmailStyle'
                          onClick={() => copyEmailToClipboard(item.email)}
                        />
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ fontSize: '14px' }}>
                      {userListVariant !== 'allUsers' ? (
                        <IconButton
                          sx={{ marginLeft: '55px' }}
                          size="medium"
                          onClick={() => {
                            setShowRemoveUserDialog(true); setSelectedUser(data?.allUsers.find((user) => user.email === item));
                          }}
                        >
                          <ClearIcon className="delete-icon" />
                        </IconButton>
                      ) : (
                        returnGroupNames(item.groups)
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                className="usersPagiCell"
                rowsPerPageOptions={[5, 10, 25]}
                count={filteredList?.flat()?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export { Users };
