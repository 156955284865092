import { GroupObject, UserObject } from './permissionTabTypes'

export const chunker = (arr: string | any[], chunkSize: number) => {
    const chunkedBatchesArray = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
        const chunkedBatchToUpload = arr.slice(i, i + chunkSize);
        chunkedBatchesArray.push(chunkedBatchToUpload);
    }
    return chunkedBatchesArray;
};

export const  filterList = (inputList: string[] | UserObject[] | any[], filterString: string, inputRowAmount: number) => {
    let tempFilteredList = inputList.filter((user: { email: string | string[]; includes: (arg0: string) => any; }) => (user?.email ? user.email.includes(filterString) : user.includes(filterString)))
    return chunker(tempFilteredList, inputRowAmount);
}


export const returnGroupNames = (groups: GroupObject[]) => {
    let groupNames = groups.map((group: { Description: string | string[]; GroupName: any; }) => {
         if (!group.Description.includes('Autogenerated')) {
             return group.GroupName
         }
         return undefined
     }).filter((group: any) => group !== undefined)
     return (groupNames.length > 0) ? `${[...groupNames]}` : 'None'
 }

export const searchBarDataComponents = {
    helperText: "Search for a specific user",
    label: "Search by E-mail",
    variant: "filled",
    size: "medium",
    wrapperClass: 'search-bar-form-control',
    textFieldClass: 'searchBarStyle'
}



