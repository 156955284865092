import {
    Modal,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TablePagination,
    TableFooter,
    InputAdornment,
    Button,
    Box
} from '@mui/material'
import { useState, useEffect } from 'react';
import TextFieldWrapper from '../../../../common/TextFieldWrapper/TextFieldWrapper';
import SearchRounded from '@mui/icons-material/SearchRounded';
import './../PermissionTabStyles.css'
import { chunker, filterList, returnGroupNames } from '../adminPanelUtils'



interface AddUsersToGroupProps {
    data: any,
    groupName: string,
    groupUserList: string[],
    isOpen: boolean,
    handleModuleClosure: any,
    setNotificationState?: any,
    handleAddUser: any

}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 420,
    width: 630,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    };
function AddUsersToGroupModal({data, groupUserList, groupName, isOpen, handleModuleClosure, handleAddUser}: AddUsersToGroupProps) {
    const [modalFilteredList, setModalFilteredList] = useState(chunker(data?.allUsers.filter(user => !groupUserList.includes(user.email)), 5));
    const [page, setPage] = useState(0)
    const [selectedRow, setSelectedRow] = useState<number>(-1);
    const [selectedItem, setSelectedItem] = useState(undefined)
    const [inputFilterText, setInputFilterText]= useState<string>('')
    const [rowsPerPage, setRowsPerPage] = useState<number>(5)

    useEffect(() => {
        setModalFilteredList(filterList(data?.allUsers.filter(user => !groupUserList.includes(user.email)), inputFilterText, rowsPerPage))
    }, [inputFilterText, data?.allUsers, groupUserList, rowsPerPage ]);



    const inputHandler = (e: any) => {
        setSelectedItem(undefined);
        setSelectedRow(undefined);
        setPage(0);
        setInputFilterText(e.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setSelectedItem(undefined);
        setSelectedRow(undefined)
        setPage(newPage);
      };

    const handleChangeRowsPerPage = async (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
      };

    return (
        <Modal
        open={isOpen}
        onClose={handleModuleClosure}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
        <TextFieldWrapper
        id='search-adminpanel-outlined-basic'
        helperText='Search for Users'
        label='Search by e-mail'
        variant='filled'
        size={'small'}
        className='searchBarStyle'
        InputProps={{
            style: {
                backgroundColor: 'off-white',
            },
            disableUnderline: true,
            endAdornment: (
                <InputAdornment position='end'>
                    <SearchRounded />
                </InputAdornment>
            ),
        }}
        onChange={inputHandler}
        />
        <TableContainer component={Paper} className={'addUserTableStyle'}>
        <Table className="tableClass" aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell className="rowCells" align="left">
                E-Mail
              </TableCell>
              <TableCell className="rowCells" align="left">
               Assigned Groups
              </TableCell>
            </TableRow>
          </TableHead>
          {modalFilteredList.length > 0 &&
            <TableBody>
                {modalFilteredList[page].map((item, index) => {
                  return  <TableRow className='addUsersModalTableRow' hover key={index} selected={selectedRow === index ? true : false } onClick={()=> {setSelectedRow(index); setSelectedItem(item)}}>
                      <TableCell
                        component="th"
                        scope="row"
                        className='addUsersModalTabEmailCell'
                      >
                        {item.email ? item.email : item}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className='addUsersModalGroupsCell'
                      >
                        {
                            returnGroupNames(item.groups)
                        }
                      </TableCell>
                      </TableRow>
})}
            </TableBody>
          }
          <TableFooter>
            <TableRow>
              <TablePagination
                className="usersPagiCell"
                rowsPerPageOptions={[5]}
                count={modalFilteredList?.flat()?.length}
                rowsPerPage={5}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box sx={{float: 'right'}}>
      <Button variant='outlined' onClick={handleModuleClosure}>
        Cancel
      </Button>
      <Button
      onClick={() => {handleAddUser(selectedItem, [])}}
      disabled={selectedItem === undefined}
      sx={{marginLeft: '20px'}}
      variant='contained'>
        Add User
      </Button>
      </Box>
        </Box>
      </Modal>
    )
}

export { AddUsersToGroupModal }
