export const experiencesList: Array<string> = [
    "ONE",
    "Coca-Cola App",
    "Daha Daha",
    "Costa",
    "Innocent",
    "Custom"
]

export const mechanicOptions: Array<string> = [
    "Instant Win",
    "Collect & Get",
    "Prize Draw (Lottery)",
];

export const flowLabelMap: { [key: string]: string } = {
    "Instant Win": "instantWin",
    "Collect & Get": "redeemPincodeForCurrencies",
    "Prize Draw (Lottery)": "promoEntry"
};

export const flowLambdas: { [key: string]: string[] } = {
    instantWin: ['instantWin'],
    redeemPincodeForCurrencies: ['burnPincodes', 'pincodeToCurrency', 'transactionLambda'],
    promoEntry: ['promoEntryLambda']
};

export const promotionMarketList: Array<string> = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahrain",
    "Belarus",
    "Belgium",
    "Belize",
    "Bolivia",
    "Bosnia And Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei Darussalam",
    "Bulgaria",
    "Canada",
    "Cambodia",
    "Chile",
    "China",
    "Colombia",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Estonia",
    "Faroe Islands",
    "Finland",
    "France",
    "French Guiana",
    "Georgia",
    "Germany",
    "Greece",
    "Guatemala",
    "Guinea",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic Of",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Korea, Democratic People's Republic Of",
    "Korea, Republic Of",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Latvia",
    "Lebanon",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "North Macedonia",
    "Malaysia",
    "Maldives",
    "Malta",
    "Mexico",
    "Moldova, Republic Of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Norway",
    "Oman",
    "Pakistan",
    "Palestine",
    "Panama",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Saudi Arabia",
    "Serbia",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "Spain",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province Of China",
    "Thailand",
    "Trinidad And Tobago",
    "Tunisia",
    "Turkey",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zimbabwe",
    "Nigeria",
];

type supportedLanguagesInterface = {
    [key: string]: string[];
};
export const supportedLanguages: supportedLanguagesInterface = {
    Afghanistan: [
        "Pashto (Afghanistan)",
    ],
    Albania: ["Albanian (Albania)"],
    Algeria: ["Arabic (Algeria)"],
    Andorra: ["Catalan"],
    Argentina: ["Spanish (Argentina)"],
    Armenia: ["Armenian (Armenia)"],
    Australia: ["English (Australia)"],
    Austria: ["German (Austria)"],
    Azerbaijan: [
        "Azeri (Latin) (Azerbaijan)",
        "Azeri (Cyrillic) (Azerbaijan)"
    ],
    Bahrain: ["Arabic (Bahrain)"],
    Belarus: ["Belarusian (Belarus)"],
    Belgium: [
        "French (Belgium)",
        "Dutch (Belgium)"
    ],
    Belize: ["English (Belize)"],
    Bolivia: ["Spanish (Bolivia)", "Quechua (Bolivia)"],
    Botswana: ["Tswana"],
    Brazil: ["Portuguese (Brazil)"],
    "Brunei Darussalam": ["Malay (Brunei Darussalam)"],
    Bulgaria: ["Bulgarian (Bulgaria)"],
    Cambodia: ["Khmer (Cambodia)"],
    Canada: ["French (Canada)", "English (Canada)"],
    Chile: ["Spanish (Chile)"],
    China: ["Chinese (S)", ],
    Colombia: ["Spanish (Colombia)"],
    "Costa Rica": ["Spanish (Costa Rica)"],
    Croatia: ["Croatian (Croatia)"],
    Cyprus: ["Greek"],
    "Democratic Republic of the Congo": ["French (Democratic Republic of the Congo)"],
    "Republic of the Congo": ["French (Republic of the Congo)"],
    "Czech Republic": ["Czech (Czech Republic)"],
    Denmark: ["Danish (Denmark)"],
    "Dominican Republic": ["Spanish (Dominican Republic)"],
    Ecuador: ["Quechua (Ecuador)"],
    Egypt: ["Arabic (Egypt)"],
    "El Salvador": ["Spanish (El Salvador)"],
    Estonia: ["Estonian (Estonia)"],
    "Faroe Islands": ["Faroese (Faroe Islands)"],
    Finland: ["Finnish (Finland)", "Swedish (Finland)", "Sami (Finland)"],
    France: ["French (France)"],
    "French Guiana": ["French"],
    Georgia: ["Georgian (Georgia)"],
    Germany: ["German (Germany)"],
    Greece: ["Greek (Greece)"],
    Guatemala: ["Spanish (Guatemala)"],
    Guinea: ["Portuguese"],
    Guyana: ["English"],
    Honduras: ["Spanish (Honduras)"],
    "Hong Kong": ["Chinese (Hong Kong)"],
    Hungary: ["Hungarian (Hungary)"],
    Iceland: ["Icelandic (Iceland)"],
    India: [
        "Hindi (India)",
        "Gujarati (India)",
        "Kannada (India)",
        "Konkani (India)",
        "Marathi (India)",
        "Punjabi (India)",
        "Sanskrit (India)",
        "Tamil (India)",
        "Telugu (India)"
    ],
    Indonesia: ["Indonesian (Indonesia)"],
    "Iran, Islamic Republic Of": ["Farsi (Iran)"],
    Iraq: ["Arabic (Iraq)"],
    Ireland: ["English (Ireland)"],
    Israel: ["Hebrew (Israel)"],
    Italy: ["Italian (Italy)"],
    Jamaica: ["English (Jamaica)"],
    Japan: ["Japanese (Japan)"],
    Jordan: ["Arabic (Jordan)"],
    Kazakhstan: ["Kazakh (Kazakhstan)"],
    Kenya: ["Swahili (Kenya)"],
    "Korea, Democratic People's Republic Of": ["Korean (Korea)"],
    "Korea, Republic Of": ["Korean (Korea)"],
    Kosovo: ["Albanian"],
    Kuwait: ["Arabic (Kuwait)"],
    Kyrgyzstan: ["Kyrgyz (Kyrgyzstan)"],
    Latvia: ["Latvian (Latvia)"],
    Lebanon: ["Arabic (Lebanon)"],
    "Libyan Arab Jamahiriya": ["Arabic (Libya)"],
    Liechtenstein: ["German (Liechtenstein)"],
    Lithuania: ["Lithuanian (Lithuania)"],
    Luxembourg: ["French (Luxembourg)", "German (Luxembourg)"],
    Macau: ["Chinese (Macau)"],
    "North Macedonia": ["FYRO Macedonian (Former Yugoslav Republic of Macedonia)"],
    Malaysia: ["Malay (Malaysia)"],
    Maldives: ["Divehi (Maldives)"],
    Malta: ["Maltese (Malta)"],
    Mexico: ["Spanish (Mexico)"],
    "Moldova, Republic Of": ["Romanian"],
    Monaco: ["French (Principality of Monaco)"],
    Mongolia: ["Mongolian (Mongolia)"],
    Montenegro: ["Serbian (Latin) (Serbia and Montenegro)","Serbian (Cyrillic) (Serbia and Montenegro)"],
    Morocco: ["Arabic (Morocco)"],
    Mozambique: ["Portuguese"],
    Netherlands: ["Dutch (Netherlands)"],
    "New Zealand": ["English (New Zealand)", "Maori (New Zealand)"],
    Nicaragua: ["Spanish (Nicaragua)"],
    Norway: ["Norwegian (Bokm?l) (Norway)", "Norwegian (Nynorsk) (Norway)", "Sami (Norway"],
    Oman: ["Arabic (Oman)"],
    Pakistan: ["Urdu (Islamic Republic of Pakistan)"],
    Palestine: ["Arabic"],
    Panama: ["Spanish (Panama)"],
    Paraguay: ["Spanish (Paraguay)"],
    Peru: ["Spanish (Peru)", "Quechua (Peru)"],
    Philippines: ["English (Republic of the Philippines)", "Tagalog (Philippines)"],
    Poland: ["Polish (Poland)"],
    Portugal: ["Portuguese (Portugal)"],
    "Puerto Rico": ["Spanish (Puerto Rico)"],
    Qatar: ["Arabic (Qatar)"],
    Romania: ["Romanian (Romania)"],
    "Russian Federation": ["Russian (Russia)", "Tatar (Russia)"],
    "Saudi Arabia": ["Arabic (Saudi Arabia)"],
    Serbia: ["Serbian (Latin) (Serbia and Montenegro)", "Serbian (Cyrillic) (Serbia and Montenegro)"],
    Singapore: ["Chinese (Singapore)"],
    Slovakia: ["Slovak (Slovakia)"],
    Slovenia: ["Slovenian (Slovenia)"],
    "South Africa": [
        "Afrikaans (South Africa)",
        "English (South Africa)",
        "Northern Sotho (South Africa)",
        "Tswana (South Africa)",
        "Xhosa (South Africa)",
        "Zulu (South Africa)"
    ],
    Spain: [
        "Catalan (Spain)",
        "Spanish (Spain)",
        "Basque (Spain)",
        "Galician (Spain)"
    ],
    Sweden: [
        "Swedish (Sweden)",
        "Sami (Sweden)"
    ],
    Switzerland: [
        "German (Switzerland)",
        "French (Switzerland)",
        "Italian (Switzerland)"
    ],
    "Syrian Arab Republic": ["Arabic (Syria)", "Syriac (Syria)"],
    "Taiwan, Province Of China": ["Chinese (T)"],
    Thailand: ["Thai (Thailand)"],
    "Trinidad And Tobago": ["English (Trinidad and Tobago)"],
    Tunisia: ["Arabic (Tunisia)"],
    Turkey: ["Turkish (Turkey)"],
    Ukraine: ["Ukrainian (Ukraine)"],
    "United Arab Emirates": ["Arabic (U.A.E.)"],
    "United Kingdom": [
        "English (United Kingdom)",
        "Welsh (United Kingdom)"
    ],
    "United States": ["English (United States)"],
    Uruguay: ["Spanish (Uruguay)"],
    Uzbekistan: [
        "Uzbek (Latin) (Uzbekistan)",
        "Uzbek (Cyrillic) (Uzbekistan)"
    ],
    Venezuela: ["Spanish (Venezuela)"],
    Vietnam: ["Vietnamese (Viet Nam)"],
    "Yemen": ["Arabic (Yemen)"],
    "Zimbabwe": ["English (Zimbabwe)"],
    Nigeria: ["English (Nigeria)"]
};

export const languageCodes: {[key: string]: string} = {
    'af': 'Afrikaans',
    'af-ZA': 'Afrikaans (South Africa)',
    'ar': 'Arabic',
    'ar-AE': 'Arabic (U.A.E.)',
    'ar-BH': 'Arabic (Bahrain)',
    'ar-DZ': 'Arabic (Algeria)',
    'ar-EG': 'Arabic (Egypt)',
    'ar-IQ': 'Arabic (Iraq)',
    'ar-JO': 'Arabic (Jordan)',
    'ar-KW': 'Arabic (Kuwait)',
    'ar-LB': 'Arabic (Lebanon)',
    'ar-LY': 'Arabic (Libya)',
    'ar-MA': 'Arabic (Morocco)',
    'ar-OM': 'Arabic (Oman)',
    'ar-QA': 'Arabic (Qatar)',
    'ar-SA': 'Arabic (Saudi Arabia)',
    'ar-SY': 'Arabic (Syria)',
    'ar-TN': 'Arabic (Tunisia)',
    'ar-YE': 'Arabic (Yemen)',
    'az': 'Azeri (Latin)',
    'az-AZ': 'Azeri (Latin) (Azerbaijan)',
    'az-Cyrl-AZ': 'Azeri (Cyrillic) (Azerbaijan)',
    'be': 'Belarusian',
    'be-BY': 'Belarusian (Belarus)',
    'bg': 'Bulgarian',
    'bg-BG': 'Bulgarian (Bulgaria)',
    'bs-BA': 'Bosnian (Bosnia and Herzegovina)',
    'ca': 'Catalan',
    'ca-ES': 'Catalan (Spain)',
    'cs': 'Czech',
    'cs-CZ': 'Czech (Czech Republic)',
    'cy': 'Welsh',
    'cy-GB': 'Welsh (United Kingdom)',
    'da': 'Danish',
    'da-DK': 'Danish (Denmark)',
    'de': 'German',
    'de-AT': 'German (Austria)',
    'de-CH': 'German (Switzerland)',
    'de-DE': 'German (Germany)',
    'de-LI': 'German (Liechtenstein)',
    'de-LU': 'German (Luxembourg)',
    'dv': 'Divehi',
    'dv-MV': 'Divehi (Maldives)',
    'el': 'Greek',
    'el-GR': 'Greek (Greece)',
    'en': 'English',
    'en-AU': 'English (Australia)',
    'en-BZ': 'English (Belize)',
    'en-CA': 'English (Canada)',
    'en-CB': 'English (Caribbean)',
    'en-GB': 'English (United Kingdom)',
    'en-IE': 'English (Ireland)',
    'en-JM': 'English (Jamaica)',
    'en-NZ': 'English (New Zealand)',
    'en-PH': 'English (Republic of the Philippines)',
    'en-TT': 'English (Trinidad and Tobago)',
    'en-US': 'English (United States)',
    'en-ZA': 'English (South Africa)',
    'en-ZW': 'English (Zimbabwe)',
    'eo': 'Esperanto',
    'es': 'Spanish',
    'es-AR': 'Spanish (Argentina)',
    'es-BO': 'Spanish (Bolivia)',
    'es-CL': 'Spanish (Chile)',
    'es-CO': 'Spanish (Colombia)',
    'es-CR': 'Spanish (Costa Rica)',
    'es-DO': 'Spanish (Dominican Republic)',
    'es-EC': 'Spanish (Ecuador)',
    'es-ES': 'Spanish (Spain)',
    'es-GT': 'Spanish (Guatemala)',
    'es-HN': 'Spanish (Honduras)',
    'es-MX': 'Spanish (Mexico)',
    'es-NI': 'Spanish (Nicaragua)',
    'es-PA': 'Spanish (Panama)',
    'es-PE': 'Spanish (Peru)',
    'es-PR': 'Spanish (Puerto Rico)',
    'es-PY': 'Spanish (Paraguay)',
    'es-SV': 'Spanish (El Salvador)',
    'es-UY': 'Spanish (Uruguay)',
    'es-VE': 'Spanish (Venezuela)',
    'et': 'Estonian',
    'et-EE': 'Estonian (Estonia)',
    'eu': 'Basque',
    'eu-ES': 'Basque (Spain)',
    'fa': 'Farsi',
    'fa-IR': 'Farsi (Iran)',
    'fi': 'Finnish',
    'fi-FI': 'Finnish (Finland)',
    'fo': 'Faroese',
    'fo-FO': 'Faroese (Faroe Islands)',
    'fr': 'French',
    'fr-CD': 'French (Democratic Republic of the Congo)',
    'fr-CG': 'French (Republic of the Congo)',
    'fr-BE': 'French (Belgium)',
    'fr-CA': 'French (Canada)',
    'fr-CH': 'French (Switzerland)',
    'fr-FR': 'French (France)',
    'fr-LU': 'French (Luxembourg)',
    'fr-MC': 'French (Principality of Monaco)',
    'gl': 'Galician',
    'gl-ES': 'Galician (Spain)',
    'gu': 'Gujarati',
    'gu-IN': 'Gujarati (India)',
    'he': 'Hebrew',
    'he-IL': 'Hebrew (Israel)',
    'hi': 'Hindi',
    'hi-IN': 'Hindi (India)',
    'hr': 'Croatian',
    'hr-BA': 'Croatian (Bosnia and Herzegovina)',
    'hr-HR': 'Croatian (Croatia)',
    'hu': 'Hungarian',
    'hu-HU': 'Hungarian (Hungary)',
    'hy': 'Armenian',
    'hy-AM': 'Armenian (Armenia)',
    'id': 'Indonesian',
    'id-ID': 'Indonesian (Indonesia)',
    'is': 'Icelandic',
    'is-IS': 'Icelandic (Iceland)',
    'it': 'Italian',
    'it-CH': 'Italian (Switzerland)',
    'it-IT': 'Italian (Italy)',
    'ja': 'Japanese',
    'ja-JP': 'Japanese (Japan)',
    'ka': 'Georgian',
    'ka-GE': 'Georgian (Georgia)',
    'kk': 'Kazakh',
    'kk-KZ': 'Kazakh (Kazakhstan)',
    'km-KH': 'Khmer (Cambodia)',
    'kn': 'Kannada',
    'kn-IN': 'Kannada (India)',
    'ko': 'Korean',
    'ko-KR': 'Korean (Korea)',
    'kok': 'Konkani',
    'kok-IN': 'Konkani (India)',
    'ky': 'Kyrgyz',
    'ky-KG': 'Kyrgyz (Kyrgyzstan)',
    'lt': 'Lithuanian',
    'lt-LT': 'Lithuanian (Lithuania)',
    'lv': 'Latvian',
    'lv-LV': 'Latvian (Latvia)',
    'mi': 'Maori',
    'mi-NZ': 'Maori (New Zealand)',
    'mk': 'FYRO Macedonian',
    'mk-MK': 'FYRO Macedonian (Former Yugoslav Republic of Macedonia)',
    'mn': 'Mongolian',
    'mn-MN': 'Mongolian (Mongolia)',
    'mr': 'Marathi',
    'mr-IN': 'Marathi (India)',
    'ms': 'Malay',
    'ms-BN': 'Malay (Brunei Darussalam)',
    'ms-MY': 'Malay (Malaysia)',
    'mt': 'Maltese',
    'mt-MT': 'Maltese (Malta)',
    'nb': 'Norwegian (Bokm?l)',
    'nb-NO': 'Norwegian (Bokm?l) (Norway)',
    'nl': 'Dutch',
    'nl-BE': 'Dutch (Belgium)',
    'nl-NL': 'Dutch (Netherlands)',
    'nn-NO': 'Norwegian (Nynorsk) (Norway)',
    'ns': 'Northern Sotho',
    'ns-ZA': 'Northern Sotho (South Africa)',
    'pa': 'Punjabi',
    'pa-IN': 'Punjabi (India)',
    'pl': 'Polish',
    'pl-PL': 'Polish (Poland)',
    'ps': 'Pashto',
    'ps-AR': 'Pashto (Afghanistan)',
    'pt': 'Portuguese',
    'pt-BR': 'Portuguese (Brazil)',
    'pt-PT': 'Portuguese (Portugal)',
    'qu': 'Quechua',
    'qu-BO': 'Quechua (Bolivia)',
    'qu-EC': 'Quechua (Ecuador)',
    'qu-PE': 'Quechua (Peru)',
    'ro': 'Romanian',
    'ro-RO': 'Romanian (Romania)',
    'ru': 'Russian',
    'ru-RU': 'Russian (Russia)',
    'sa': 'Sanskrit',
    'sa-IN': 'Sanskrit (India)',
    'se': 'Sami',
    'se-FI': 'Sami (Finland)',
    'se-NO': 'Sami (Norway)',
    'se-SE': 'Sami (Sweden)',
    'sk': 'Slovak',
    'sk-SK': 'Slovak (Slovakia)',
    'sl': 'Slovenian',
    'sl-SI': 'Slovenian (Slovenia)',
    'sq': 'Albanian',
    'sq-AL': 'Albanian (Albania)',
    'sr-BA': 'Serbian (Latin) (Bosnia and Herzegovina)',
    'sr-Cyrl-BA': 'Serbian (Cyrillic) (Bosnia and Herzegovina)',
    'sr-SP': 'Serbian (Latin) (Serbia and Montenegro)',
    'sr-Cyrl-SP': 'Serbian (Cyrillic) (Serbia and Montenegro)',
    'sv': 'Swedish',
    'sv-FI': 'Swedish (Finland)',
    'sv-SE': 'Swedish (Sweden)',
    'sw': 'Swahili',
    'sw-KE': 'Swahili (Kenya)',
    'syr': 'Syriac',
    'syr-SY': 'Syriac (Syria)',
    'ta': 'Tamil',
    'ta-IN': 'Tamil (India)',
    'te': 'Telugu',
    'te-IN': 'Telugu (India)',
    'th': 'Thai',
    'th-TH': 'Thai (Thailand)',
    'tl': 'Tagalog',
    'tl-PH': 'Tagalog (Philippines)',
    'tn': 'Tswana',
    'tn-ZA': 'Tswana (South Africa)',
    'tr': 'Turkish',
    'tr-TR': 'Turkish (Turkey)',
    'tt': 'Tatar',
    'tt-RU': 'Tatar (Russia)',
    'ts': 'Tsonga',
    'uk': 'Ukrainian',
    'uk-UA': 'Ukrainian (Ukraine)',
    'ur': 'Urdu',
    'ur-PK': 'Urdu (Islamic Republic of Pakistan)',
    'uz': 'Uzbek (Latin)',
    'uz-UZ': 'Uzbek (Latin) (Uzbekistan)',
    'uz-Cyrl-UZ': 'Uzbek (Cyrillic) (Uzbekistan)',
    'vi': 'Vietnamese',
    'vi-VN': 'Vietnamese (Viet Nam)',
    'xh': 'Xhosa',
    'xh-ZA': 'Xhosa (South Africa)',
    'zh': 'Chinese',
    'zh-CN': 'Chinese (S)',
    'zh-HK': 'Chinese (Hong Kong)',
    'zh-MO': 'Chinese (Macau)',
    'zh-SG': 'Chinese (Singapore)',
    'zh-TW': 'Chinese (T)',
    'zu': 'Zulu',
    'zu-ZA': 'Zulu (South Africa)',
    'en-NG': 'English (Nigeria)',
  }

export const marketBrands: Array<string> = [
    'Coca-Cola',
    'Diet Coke',
    'Coke Zero',
    'Coca-Cola Energy',
    'Sprite',
    'Sprite Zero Sugar',
    'Sprite Cranberry',
    'Fanta',
    'Fanta Zero',
    'Fanta Grape',
    'Fanta Pineapple',
    'Dasani',
    'smartwater',
    'Minute Maid',
    'innocent',
    'Simply',
    'Georgia Coffee',
    'Costa Coffee',
    'Fuse Tea',
    'Honest',
    'fairlife',
    'Powerade',
    'Ciel',
    'Schweppes',
    'vitaminwater',
    'Gold Peak',
    'Appletiser',
    'Topo Chico',
    'Aquarius',
    'AdeS',
    'Fresca',
    'I LOHAS',
    'Ayataka',
    "Barq's",
    'Dogadan',
    'Peace Tea'
]

export const barcodeType: Array<{
    value: string;
    label: string;
}> = [
    { value: '0', label: 'None' },
    { value: '6', label: 'Aztec Code' },
    { value: '2', label: 'Code 128' },
    { value: '3', label: 'Code 32' },
    { value: '4', label: 'Data Matrix' },
    { value: '1', label: 'QR Code' },
    { value: '5', label: 'UPC' },
];

export const deliveryType: Array<{
    value: string;
    label: string;
}> = [
    { value: '1', label: 'Digital' },
    { value: '2', label: 'Physical' },
];
