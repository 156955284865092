import { commonLinks, currenciesRef, instantWinRef, prizesRef } from "../../../../constants/helpful-link";
import { PromoMechanic } from "../../../../types/componentTypes/editPromotion";

export const helpfulLinks = {
    [PromoMechanic.InstantWin]: [
        {
            link: prizesRef.VOUCHER_HISTORY,
            text: 'Vouchers History'
        },
        {
            link: prizesRef.PRIZE_CATALOGUE,
            text: 'Prize Catalog'
        },
        {
            link: instantWinRef.IW_INTERRUPTED_FLOW,
            text: 'Interrupted Flow'
        },
        {
            link: commonLinks.ADD_RECAPTCHA,
            text: 'Add Captcha secret (Wiki and Video)'
        }
    ],
    [PromoMechanic.CollectAndGet]: [
        {
            link: prizesRef.VOUCHER_HISTORY,
            text: 'Vouchers History'
        },
        {
            link: prizesRef.PRIZE_CATALOGUE,
            text: 'Prize Catalog'
        },
        {
            link: currenciesRef.CURRENCY_REDUCER,
            text: 'Wallet'
        }
    ],
    [PromoMechanic.AutoRedeemCnG]: [
        {
            link: prizesRef.VOUCHER_HISTORY,
            text: 'Vouchers History'
        },
        {
            link: prizesRef.PRIZE_CATALOGUE,
            text: 'Prize Catalog'
        },
        {
            link: currenciesRef.CURRENCY_REDUCER,
            text: 'Wallet'
        }
    ],
    [PromoMechanic.PromoEntry]: [
        {
            link: commonLinks.ADD_RECAPTCHA,
            text: 'Add Captcha secret (Wiki and Video)'
        }
    ]
};
